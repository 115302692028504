//Library Imports

import React, { useEffect, useRef, useState } from 'react';
import { Box, IconButton } from '@mui/material';
import ArrowBackIosRoundedIcon from '@mui/icons-material/ArrowBackIosRounded';
import ArrowForwardIosRoundedIcon from '@mui/icons-material/ArrowForwardIosRounded';
import { secondaryColor } from '../../utils/style/GlobalVariables';

// Local Imports
const ImageGalleryNew = ({ images }) => {
  const [isScrollBarVisible, setIsScrollBarVisible] = useState(false);

  const isEnableScroll = useRef(null);
  const [currentIndex, setCurrentIndex] = useState(0);

  const handlePrevClick = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? images.length - 1 : prevIndex - 1
    );
  };

  const handleNextClick = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === images.length - 1 ? 0 : prevIndex + 1
    );
  };
  const handleThumbnailClick = (index) => {
    setCurrentIndex(index);
  };

  // Handle Thumbnail Scroll
  const handleScrollLeft = () => {
    if (isEnableScroll.current) {
      isEnableScroll.current.scrollLeft -= 112;
    }
  };

  const handleScrollRight = () => {
    if (isEnableScroll.current) {
      isEnableScroll.current.scrollLeft += 112;
    }
  };

  useEffect(() => {
    const container = isEnableScroll.current;
    const handleScroll = () => {
      if (container) {
        const hasScrollBar = container.scrollWidth > container.clientWidth;
        if (hasScrollBar) {
          setIsScrollBarVisible(hasScrollBar);
        } else {
          setIsScrollBarVisible(false);
        }
        container.addEventListener('scroll', handleScroll);
      }
    };
    // Initial check when component mounts
    handleScroll();

    // Clean up the event listener on component unmount
    return () => {
      if (container) {
        container.removeEventListener('scroll', handleScroll);
      }
    };
  }, [
    isEnableScroll.current?.clientWidth,
    isEnableScroll.current?.scrollWidth,
  ]);

  return (
    <React.Fragment>
      <div className='flex items-center justify-center relative w-full px-4 sm:px-0'>
        <IconButton
          onClick={handlePrevClick}
          sx={{
            backgroundColor: 'black',
            color: 'white',
            borderRadius: '12px',
            position: 'absolute',
            left: '10px',
            zIndex: 1,
            '&:hover': {
              backgroundColor: 'secondary.main',
            },
          }}
        >
          <ArrowBackIosRoundedIcon />
        </IconButton>
        <Box width='100%'>
          <Box>
            <img
              src={images[currentIndex]}
              alt={`Image ${currentIndex}`}
              // width={1000}
              // height={1000}
              style={{
                maxHeight: '700px',
                height: '550px',
                width: '100%',
                objectFit: 'cover',
                borderRadius: '20px',
                transition: '0.5s ease in-out',
              }}
            />
          </Box>
          <Box
            display='flex'
            justifyContent='center'
            alignItems='center'
            marginTop='-100px'
            width={'100%'}
            gap='10px'
          >
            {(images.length > 6 || isScrollBarVisible) && (
              <IconButton onClick={handleScrollLeft}>
                <ArrowBackIosRoundedIcon />
              </IconButton>
            )}
            <Box
              display='flex'
              alignItems='center'
              justifyContent={isScrollBarVisible ? 'unset' : 'center'}
              width={'100%'}
              gap='10px'
              sx={{
                overflow: 'scroll',
              }}
              ref={isEnableScroll}
              component='div'
            >
              {images?.map((image, index) => (
                <img
                  key={index}
                  src={image}
                  alt={`Thumbnail ${index}`}
                  className={`thumbnail ${index === currentIndex ? 'active transition-all ' : ''
                    }`}
                  onClick={() => handleThumbnailClick(index)}
                  style={{
                    height: '78px',
                    width: '78px',
                    objectFit: 'cover',
                    borderRadius: '12px',
                    transition: '0.5s ease in-out',
                    cursor: 'pointer',
                    border: `${index === currentIndex
                      ? '1px solid white'
                      : '1px solid transparent'
                      }`,
                  }}
                />
              ))}
            </Box>
            {(images?.length > 6 || isScrollBarVisible) && (
              <IconButton onClick={handleScrollRight}>
                <ArrowForwardIosRoundedIcon />
              </IconButton>
            )}
          </Box>
        </Box>
        <IconButton
          onClick={handleNextClick}
          sx={{
            backgroundColor: 'black',
            borderRadius: '12px',
            color: 'white',
            position: 'absolute',
            right: '10px',
            zIndex: 1,
            '&:hover': {
              backgroundColor: 'secondary.main',
            },
          }}
        >
          <ArrowForwardIosRoundedIcon />
        </IconButton>
      </div>
    </React.Fragment>
  );
};
export default ImageGalleryNew;