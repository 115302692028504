import React, {
  useRef,
  useState,
  useEffect,
  forwardRef,
  useImperativeHandle,
  useCallback,
} from 'react';
import { Box } from '@mui/material';
// Local Imports
import { toolBar, fontSizeList, useOutsideClick } from '../../utils/utils';
import {
  gray,
  primaryColor,
  secondaryColor,
  yellow,
} from '../../utils/style/GlobalVariables';
import {
  createImageTag,
  createVideoTag,
  LinkPopover,
} from './editorcomponents';

// CSS
import './editor.css';
// Local Imports

const CustomEditor = forwardRef((props, ref) => {
  const {
    id,
    newPost,
    imageSize,
    setValue,
    setImageSizeError,
    setVideoSizeError,
    setIsShowBlock,
    imageWidth,
    imageHeight,
    setImageWidth,
    setImageHeight,
    editorHTML,
    letterCases,
    setLetterCases,
    setEditorHTML,
    videoSettings,
    setVideoSettings,
    setNewPostMedia,
    setDeletedIds,
    links,
    deleteBlogContent,
  } = props;
  // Editor States
  const [activeCommands, setActiveCommands] = useState({});
  // Refs
  const editorRef = useRef();
  const popoverRef = useRef();
  // Editor States And Methods
  const [selectedImage, setSelectedImage] = useState(null);
  const [selectedVideo, setSelectedVideo] = useState(null);
  const [showLinkPopover, setShowLinkPopover] = useState(false);
  const [selectedText, setSelectedText] = useState('');
  const [selectedRange, setSelectedRange] = useState(null);
  const [url, setUrl] = useState('');
  const [anchorTag, setAnchorTag] = useState('');
  const [popoverPositions, setPopoverPosition] = useState({ x: '', y: '' });
  const [isCapitilize, setIsCapitilize] = useState(false);
  const [isUpperCase, setIsUpperCase] = useState(false);
  const [isLowerCase, setIsLowerCase] = useState(false);
  const [isLinkAdded, setIsLinkAdded] = useState(false);
  const [fontSize, setFontSize] = useState('');
  const [totalImgVideoTags, setTotalImgVideoTags] = useState([]);
  const [imgVideoTagsToRemove, setImgVideoTagsToRemove] = useState([]);

  //************************************ Handle Manage Editor  Commands **********************************/ //
  if (editorRef.current?.innerHTML === '') {
    editorRef.current.innerHTML = editorHTML;
  } else if (
    deleteBlogContent &&
    editorRef.current?.innerHTML !== '' &&
    editorRef.current?.innerHTML !== null &&
    editorRef.current?.innerHTML !== undefined
  ) {
    editorRef.current.innerHTML = '';
  }

  const handleMangeCmd = async (e, item) => {
    e.preventDefault();
    const { exeCmd, absence } = item;

    // Get Selection
    const selection = document.getSelection();
    if (!selection || selection.rangeCount === 0) {
      return;
    }
    const range = selection.getRangeAt(0);
    const parentElement = range.commonAncestorContainer.parentNode;
    if (!editorRef.current?.contains(parentElement)) {
      return;
    }

    // For Deselect Alignments
    if (exeCmd?.startsWith('justify')) {
      const alignmentCommands = [
        'justifyLeft',
        'justifyCenter',
        'justifyRight',
      ];
      alignmentCommands?.forEach((alignment) => {
        if (alignment !== exeCmd) {
          setActiveCommands((prevState) => ({
            ...prevState,
            [alignment]: false,
          }));
        }
      });
    }

    // For Unordered List
    if (exeCmd === 'insertUnorderedList') {
      document.execCommand(exeCmd, false, absence);
      return;
    }

    // Handle headings
    if (exeCmd?.startsWith('heading')) {
      const headingLevel = exeCmd.replace('heading', '');
      const tagName = `h${headingLevel}`;
      const fontSizeMap = {
        1: '36px',
        2: '28px',
        3: '20px',
      };
      if (parentElement?.tagName?.toLowerCase() === tagName) {
        document.execCommand('formatBlock', false, 'p');
        const newHeading = selection.focusNode?.parentNode;
        if (newHeading.style) {
          newHeading.style.fontSize = '';
        }
        setActiveCommands({
          heading1: false,
          heading2: false,
          heading3: false,
        });
      } else {
        document.execCommand('formatBlock', false, `<${tagName}>`);
        const newHeading = selection.focusNode?.parentNode;
        if (newHeading) {
          newHeading.style.fontSize = fontSizeMap[headingLevel];
        }

        setActiveCommands({
          heading1: headingLevel === '1',
          heading2: headingLevel === '2',
          heading3: headingLevel === '3',
        });
      }
      return;
    }

    const isActive = activeCommands[exeCmd];
    setActiveCommands((prevState) => ({
      ...prevState,
      [exeCmd]: !isActive,
    }));
    document.execCommand(exeCmd, false, absence);
  };

  const handleImageAndVdieoByCMD = (e, item) => {
    editorRef.current?.focus();
    const { exeCmd } = item;

    const isActive = activeCommands[exeCmd];
    setActiveCommands((prevState) => ({
      ...prevState,
      [exeCmd]: !isActive,
    }));
    // Insert Image
    if (exeCmd === 'insertImage') {
      createImageTag(
        editorRef,
        exeCmd,
        setIsShowBlock,
        setActiveCommands,
        setImageSizeError,
        setNewPostMedia,
        setEditorHTML
      );
    }
    // Insert Video
    if (exeCmd === 'insertVideo') {
      createVideoTag(
        editorRef,
        exeCmd,
        setIsShowBlock,
        setActiveCommands,
        setVideoSizeError,
        setNewPostMedia
      );
    }
  };

  // ************************************** Handle Open CreateLink **************************************//
  const handleOpenLinkPopover = () => {
    setUrl('');
    const selection = window.getSelection();
    const selectedText = selection.toString();
    if (selectedText.length > 0) {
      const range = selection.getRangeAt(0);
      const rect = range.getBoundingClientRect();
      setSelectedText(selection?.toString());
      setSelectedRange(range);
      setShowLinkPopover(true);
      const tooltipX = rect.x + window.scrollX;
      const tooltipY = rect.y + window.scrollY + rect.height;
      setPopoverPosition({ x: tooltipX, y: tooltipY });
    }
  };

  // HANDLE LINK ADDED
  const handleLinkAdded = (url) => {
    if (selectedRange && selectedText) {
      const a = document.createElement('a');
      a.innerHTML = selectedText;
      a.href = url;
      a.target = '_blank';
      selectedRange.deleteContents();
      selectedRange.insertNode(a);
    }
    setEditorHTML(editorRef.current.innerHTML);
    setShowLinkPopover(false);
    setIsLinkAdded(false);
  };

  // HANDLE REMOVE LINK
  const handleRemoveLink = () => {
    if (anchorTag) {
      // Get the text content of the hyperlink
      const textContent = anchorTag.textContent;
      // Replace the anchor tag with its text content
      const textNode = document.createTextNode(textContent);
      const parent = anchorTag.parentNode;
      if (parent) {
        parent.replaceChild(textNode, anchorTag);
      }
      // Update the editor HTML and close the popover
      setEditorHTML(editorRef.current.innerHTML);
      setShowLinkPopover(false);
    }
    setEditorHTML(editorRef.current.innerHTML);
    setShowLinkPopover(false);
    setIsLinkAdded(false);
  };

  // HANDLE UPDATE LNK
  const handleUpdateLink = (newUrl) => {
    if (anchorTag) {
      anchorTag.href = newUrl;
      anchorTag.target = '_blank';
      setEditorHTML(editorRef.current.innerHTML);
      setShowLinkPopover(false);
      setIsLinkAdded(false);
    }
  };

  // ************************************** Handle Change Font Size **************************************//
  const handleOnChangeFontSize = (event) => {
    setFontSize(event.target.value);
    if (editorRef.current) {
      const selection = window.getSelection();
      const range = selection.getRangeAt(0);
      if (selection.rangeCount) {
        var e = document.createElement('span');
        e.style = `font-size: ${event.target.value};`;
        e.innerHTML = selection.toString();
        range.deleteContents();
        range.insertNode(e);
      }
    }
  };

  // ************************************** Handle Typo Options **************************************//
  const handleTypoOption = (type) => {
    if (editorRef.current) {
      editorRef.current.focus();
      const range = document.createRange();
      const selection = window.getSelection();
      range.selectNodeContents(editorRef.current);
      range.collapse(false);
      selection.removeAllRanges();
      selection.addRange(range);
      if (type === 'underline') {
        setLetterCases((cases) => ({
          ...cases,
          underscore: !letterCases.underscore,
        }));
        document.execCommand('underline', false, null);
      }
      if (type === 'capitalize') {
        setIsCapitilize(!isCapitilize);
        setIsUpperCase(false);
        setIsLowerCase(false);
        setLetterCases((cases) => ({
          ...cases,
          upperCase: false,
          lowerCase: false,
          capitilize: !letterCases.capitilize,
        }));
        if (isCapitilize) {
          document.execCommand('removeFormat', true, null);
        } else {
          document.execCommand('formatblock', false, 'p');
        }
      }
      if (type === 'uppercase') {
        setIsUpperCase(!isUpperCase);
        setIsCapitilize(false);
        setLetterCases((cases) => ({
          ...cases,
          lowerCase: false,
          capitilize: false,
          upperCase: !letterCases.upperCase,
        }));
        if (isUpperCase) {
          console.log('in if');
          document.execCommand('removeFormat', true, null);
        } else {
          console.log('in els');
          document.execCommand('formatblock', false, 'p');
        }
      }
      if (type === 'lowercase') {
        setIsLowerCase(!isLowerCase);
        setIsCapitilize(false);
        setIsUpperCase(false);
        setLetterCases((cases) => ({
          ...cases,
          capitilize: false,
          upperCase: false,
          lowerCase: !letterCases.lowerCase,
        }));
        if (isLowerCase) {
          document.execCommand('removeFormat', true, null);
        } else {
          document.execCommand('formatblock', false, 'p');
        }
      }
    }
  };

  // useImperative
  useImperativeHandle(ref, () => ({
    handleTypoOption: handleTypoOption,
    updateLinks: () => {
      if (editorRef.current) {
        let updatedText = editorRef.current.innerHTML;
        // updatedText = updatedText.replace(/<a\b[^>]*>(.*?)<\/a>/gi, '$1');
        // console.log('updatedText', updatedText);
        // console.log('updatedText', updatedText);
        // links.forEach((link) => {
        //   // Replace keyword with link
        //   const escapedKeyword = link.keyword.replace(
        //     /\b[.*+?^${}()|[\]\\]\b/g,
        //     '\\$&'
        //   );
        //   // Replace keyword that is not part of other word with link
        //   const regex = new RegExp(
        //     `\\b${escapedKeyword}\\b(?!([^<]+)?>)`,
        //     'gi'
        //   );
        //   updatedText = updatedText.replace(
        //     regex,
        //     (match) =>
        //       `<a href="${link.link}" target="_blank" data-link="${link.link}" >${match}</a>`
        //   );
        // });

        updatedText = updatedText.replace(
          /<a\b[^>]*data-link="[^"]*"[^>]*>(.*?)<\/a>/gi,
          '$1'
        );
        links.forEach((link) => {
          const escapedKeyword = link.keyword.replace(
            /[.*+?^${}()|[\]\\]/g,
            '\\$&'
          );
          const regex = new RegExp(`\\b${escapedKeyword}\\b`, 'gi');
          updatedText = updatedText.replace(
            regex,
            (match) =>
              `<a href="${link.link}" target="_blank" data-link="${link.link}">${match}</a>`
          );
        });
        editorRef.current.innerHTML = updatedText;
        setEditorHTML(updatedText);
      }
    },
  }));

  // ************************************** Get Filtered Tags **************************************//
  const getTmgAndVideoTags = (html) => {
    const doc = new DOMParser().parseFromString(html, 'text/html');
    const tags = [...doc.querySelectorAll('video, img')];
    return tags.map((tag) => tag.outerHTML);
  };

  const filteredVideoImgTags = (html) => {
    const currentTags = getTmgAndVideoTags(html);
    const removedTags = totalImgVideoTags.filter(
      (tag) => !currentTags.includes(tag)
    );
    setImgVideoTagsToRemove(() => {
      return removedTags;
    });
  };

  // UseEffect
  useEffect(() => {
    if (editorRef.current) {
      editorRef?.current?.addEventListener('click', (event) => {
        if (event.target.tagName === 'IMG') {
          const parent = event.target.parentElement;
          const childButton = parent.querySelector('.remove-img-btn');
          setValue(1);
          setIsShowBlock(() => ({
            isTypoOptions: false,
            isVideoOptions: false,
            isImageOptions: true,
          }));
          const img = event.target;
          setSelectedImage(img);
          setImageWidth('');
          setImageHeight('');
          childButton.addEventListener('click', () => {
            const imgId = Number(img.id);
            const filterIds = newPost?.imagesForEdit?.find(
              (_) => _.id == imgId
            );
            setDeletedIds((prev) => {
              return [...prev, filterIds];
            });
            parent.remove();
          });
          setEditorHTML(editorRef.current.innerHTML);
        } else if (event.target.tagName === 'VIDEO') {
          const videoParent = event.target.parentElement;
          const childButton = videoParent.querySelector('.remove-video-btn');
          setValue(1);
          const video = event.target;
          const { autoplay, loop, muted, controls, playsInline } = event.target;
          setVideoSettings({
            autoplay: autoplay,
            loop: loop,
            muted: muted,
            playbackControls: controls,
            playInline: playsInline,
          });
          setSelectedVideo(video);
          setIsShowBlock(() => ({
            isTypoOptions: false,
            isImageOptions: false,
            isVideoOptions: true,
          }));
          childButton.addEventListener('click', () => {
            const id = Number(video.id);
            const filterIds = newPost?.imagesForEdit?.find((_) => _.id == id);
            setDeletedIds((prev) => {
              return [...prev, filterIds];
            });
            videoParent.remove();
          });
          setEditorHTML(editorRef.current.innerHTML);
        } else if (event.target.tagName === 'A') {
          const selection = window.getSelection();
          const range = selection.getRangeAt(0);
          const getTag = event.target;
          const url = getTag.getAttribute('href');
          setSelectedRange(range);
          setSelectedText(getTag.textContent);
          setIsLinkAdded(true);
          setAnchorTag(getTag);
          setUrl(url);
          setShowLinkPopover(true);
          setPopoverPosition({ x: event.clientX, y: event.clientY });
        } else {
          setValue(1);
          setIsShowBlock(() => ({
            isTypoOptions: true,
            isImageOptions: false,
            isVideoOptions: false,
          }));
          setFontSize('');
        }
      });

      // Check Letter Cases
      if (editorRef.current) {
        editorRef.current?.addEventListener('keydown', (event) => {
          const selection = window.getSelection();
          if (selection.rangeCount > 0) {
            const range = selection.getRangeAt(0);
            const element = range.commonAncestorContainer.parentElement;
            if (element.tagName === 'P') {
              if (isCapitilize) {
                element.style.textTransform = 'capitalize';
              } else if (isUpperCase) {
                element.style.textTransform = 'uppercase';
              } else if (isLowerCase) {
                element.style.textTransform = 'lowercase';
              } else {
                element.style.textTransform = 'lowercase';
              }
            }
          } else {
            // Move the cursor to the parent <div> in the 'else' condition
            const divElement = container.closest('div');
            if (divElement) {
              const newRange = document.createRange();
              newRange.selectNodeContents(divElement);
              newRange.collapse(false);
              selection.removeAllRanges();
              selection.addRange(newRange);
            }
          }
          if (event.key === 'Enter') {
            const selection = window.getSelection();
            const range = selection.getRangeAt(0);
            const parentSpan = range.commonAncestorContainer.parentElement;
            if (parentSpan.tagName === 'SPAN') {
              const span = parentSpan;
              const textNode = document.createTextNode('\u200B');
              span.parentNode.insertBefore(textNode, span.nextSibling);
              const range = document.createRange();
              range.setStart(textNode, 1);
              range.collapse(true);
              const selection = window.getSelection();
              selection.removeAllRanges();
              selection.addRange(range);
              event.preventDefault();
            }
          }
        });
      }
    }
  }, [editorRef.current, , isCapitilize, isLowerCase, isUpperCase]);

  // UseEffect For Change Run Time Image Width And Height
  useEffect(() => {
    if (selectedImage !== null) {
      selectedImage.style.width =
        imageSize?.value === 'default' ? `auto` : `${imageWidth}px`;
      selectedImage.style.height =
        imageSize?.value === 'default' ? `auto` : `${imageHeight}px`;
    }
  }, [imageWidth, imageHeight, imageSize]);

  // Update Video Controls For Every Video
  useEffect(() => {
    if (selectedVideo !== null) {
      selectedVideo.autoplay = videoSettings?.autoplay;
      selectedVideo.loop = videoSettings.loop;
      selectedVideo.muted = videoSettings.muted;
      selectedVideo.controls = videoSettings.playbackControls;
      selectedVideo.playInline = videoSettings.playInline;
    }
  }, [videoSettings, setVideoSettings]);

  // Update DIV content when Update
  useEffect(() => {
    if (id) {
      const newHTML = fnToSetURLs(editorHTML, newPost?.imagesForEdit);
      setTotalImgVideoTags(getTmgAndVideoTags(newHTML));
      editorRef.current.innerHTML = newHTML;
    }
  }, [id]);

  // Update Active Heading
  useEffect(() => {
    const updateActiveHeading = () => {
      const selection = document.getSelection();
      if (!selection.rangeCount) return;

      const parentElement = selection.focusNode?.parentElement;
      const tagName = parentElement?.tagName?.toLowerCase();

      // Update active commands based on tag
      setActiveCommands((prevState) => ({
        ...prevState,
        heading1: tagName === 'h1',
        heading2: tagName === 'h2',
        heading3: tagName === 'h3',
      }));
    };

    // Add selection change listener
    document.addEventListener('selectionchange', updateActiveHeading);

    // Cleanup on unmount
    return () => {
      document.removeEventListener('selectionchange', updateActiveHeading);
    };
  }, []);

  function fnToSetURLs(html, orignalURls) {
    const parser = new DOMParser();
    const doc = parser.parseFromString(html, 'text/html');
    const images = doc.querySelectorAll('img');
    const videos = doc.querySelectorAll('video');
    const imgURLs = orignalURls?.filter(
      (img) => img.featured !== 'featured' && !img?.is_video
    );
    const videoURLs = orignalURls?.filter((video) => video?.is_video);
    images.forEach((img, index) => {
      let url;
      url = imgURLs[index]?.url;
      img.src = url;
      img.id = imgURLs[index]?.id;
    });
    videos.forEach((video, index) => {
      let url;
      url = videoURLs[index]?.url;
      video.src = url;
      video.id = videoURLs[index]?.id;
      video.autoplay = videoURLs[index]?.autoplay;
      video.loop = videoURLs[index]?.loop;
      video.muted = videoURLs[index]?.muted;
      video.playsInline = videoURLs[index]?.play_inline;
      video.controls = videoURLs[index]?.playback_controls;
    });
    return doc.body.innerHTML;
  }

  // Handle To Get Ids In Edit
  const handleImgVideoTagsToRemove = useCallback(() => {
    if (imgVideoTagsToRemove.length > 0) {
      setDeletedIds((prev) => {
        const ids = imgVideoTagsToRemove?.map((tag) => {
          let tagHTML = new DOMParser()
            .parseFromString(tag, 'text/html')
            .querySelectorAll('img, video');
          let id = +tagHTML[0].id;
          if (!prev?.some((item) => item?.id === id)) {
            return newPost?.imagesForEdit?.find((img) => img?.id == id);
          } else {
            return null;
          }
        });
        const filterIds = ids?.filter((id) => id !== null && id !== undefined);
        return [...prev, ...filterIds];
      });
    }
  }, [imgVideoTagsToRemove, newPost]);

  useEffect(() => {
    handleImgVideoTagsToRemove();
  }, [imgVideoTagsToRemove]);

  useEffect(() => {
    const handleToggleBold = (event) => {
      event.preventDefault();
      if (event.target.tagName === 'B') {
        const isBold = document.queryCommandEnabled('bold');
        if (isBold) {
          setActiveCommands((prevState) => ({
            ...prevState,
            bold: true,
          }));
        }
      } else {
        setActiveCommands((prevState) => ({
          ...prevState,
          bold: false,
        }));
      }
    };

    const handleKeyDown = (event) => {
      if (event.ctrlKey && event.key === 'b') {
        event.preventDefault();
        const isBold = !activeCommands.bold;
        setActiveCommands((prevState) => ({
          ...prevState,
          bold: isBold,
        }));
        document.execCommand('bold', false, null);
      }
    };

    if (editorRef.current) {
      editorRef.current.addEventListener('click', handleToggleBold);
      // document.addEventListener('keydown', handleKeyDown);
    }

    return () => {
      if (editorRef.current) {
        editorRef.current.removeEventListener('click', handleToggleBold);
      }
      // document.removeEventListener('keydown', handleKeyDown);
    };
  }, [editorRef.current]);

  return (
    <Box className='w-full min-h-[300px] max-h-max  bg-[#1B1B23] rounded-lg mt-3 '>
      <Box
        display='flex'
        justifyContent='start'
        borderBottom={`2px solid ${primaryColor}`}
        className='sticky'
      >
        <select
          onChange={handleOnChangeFontSize}
          value={fontSize}
          className='font-["Inter"] focus:outline-none px-2 rounded-lg text-[13px]'
          style={{ backgroundColor: secondaryColor, color: gray }}
        >
          {fontSizeList.map((font, index) => (
            <option value={font.value} key={index}>
              {font.label}
            </option>
          ))}
        </select>
        {toolBar?.map((item, index) => {
          return (
            <React.Fragment key={index}>
              <div
                style={{
                  borderRight:
                    item?.exeCmd === 'bold'
                      ? `2px solid ${primaryColor}`
                      : 'unset',
                  padding: '10px 5px',
                }}
              >
                <button
                  key={index}
                  onMouseDown={(e) => {
                    if (
                      item?.exeCmd === 'insertImage' ||
                      item?.exeCmd === 'insertVideo'
                    ) {
                      handleImageAndVdieoByCMD(e, item);
                    } else if (item?.exeCmd === 'createLink') {
                      handleOpenLinkPopover();
                    } else {
                      handleMangeCmd(e, item);
                    }
                  }}
                  className={` ${
                    activeCommands[item.exeCmd]
                      ? 'bg-gray-300 rounded-[2px]'
                      : 'unset rounded-none '
                  } `}
                >
                  {item?.icon}
                </button>
              </div>
            </React.Fragment>
          );
        })}
      </Box>

      <div
        ref={editorRef}
        className='contentEditableArea max-h-[445px] min-h-[240px] overflow-scroll mt-1 border-none outline-none px-[10px] py-[5px] bg-transparent text-white text-[16px] font-["Inter"]'
        id='contentEditableArea'
        contentEditable={true}
        role='textbox'
        onPaste={(e) => {
          e.preventDefault();
          const clipboardData = e.clipboardData || window.clipboardData;
          const html = clipboardData.getData('text/html');

          if (html) {
            // Create a temporary container to parse HTML
            const tempDiv = document.createElement('div');
            tempDiv.innerHTML = html;

            // Update text color to white
            tempDiv.querySelectorAll('*').forEach((node) => {
              node.style.color = 'white';
            });

            // Insert the modified content
            const modifiedHtml = tempDiv.innerHTML;
            document.execCommand('insertHTML', false, modifiedHtml);
          } else {
            // Fallback for plain text
            const text = clipboardData.getData('text/plain');
            document.execCommand('insertText', false, text);
          }
        }}
        onInput={(e) => {
          setEditorHTML(e.target.innerHTML);
          const updatedContent = e.target.innerHTML;
          filteredVideoImgTags(updatedContent);
          if (e.target.innerText === '<br>') {
            setActiveCommands({});
            setIsCapitilize(false);
            setIsLowerCase(false);
            setIsUpperCase(false);
          }
        }}
      />
      {showLinkPopover ? (
        <LinkPopover
          onLinkAdded={handleLinkAdded}
          onClose={() => {
            setShowLinkPopover(false);
            setIsLinkAdded(false);
            setUrl('');
            setSelectedRange(null);
          }}
          position={popoverPositions}
          url={url}
          setUrl={setUrl}
          isShowPopover={showLinkPopover}
          handleRemoveLink={handleRemoveLink}
          handleUpdateLink={handleUpdateLink}
          isLinkAdded={isLinkAdded}
          ref={popoverRef}
        />
      ) : null}
    </Box>
  );
});
// const BlogEditor = forwardRef(CustomEditor);
export default CustomEditor;
