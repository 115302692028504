import { useState, useEffect } from 'react';
import UnitPageUI from './UnitPageUI';
import {
  unitPageColumnData,
  unitPageColumnExtensionsData,
} from '../../../../../components/Tables/dummyData';
import {
  CampusPageStatus as UnitPageStatus,
  EditAction,
  CampusPageDate,
  setName,
} from '../../../../../components/Tables/utils';
import ApiController from '../../../../../utils/network/api';
import UnitPageEdit from './UnitPageEdit';
import { getCurrentDate } from '../../../../../utils/utils';
import { UNITBASEURLFORWEB } from '../../../../../utils/network/constants';

const UnitPage = () => {
  const date = getCurrentDate();
  const [selectionIds, setSelectionIds] = useState([]);
  const [unitListing, setUnitListing] = useState([]);
  const [unitListLoading, setUnitListLoading] = useState(false);
  const [unitPageLoading, setUnitPageLoading] = useState(false);
  const [unitPageBtnLoading, setUnitPageBtnLoading] = useState(false);
  const [campusesList, setCampusesList] = useState([]);
  const [campusPageListLoading, setcampusPageListLoading] = useState(false);
  const [unitData, setUnitData] = useState({});
  const [error, setError] = useState({
    unitTitle: '',
    unitDescription: '',
    unitImages: '',
  });
  const [uriError, setUriError] = useState({});
  const [status, setStatus] = useState(false);
  const [images, setImages] = useState([]);
  const [deletedImages, setDeletedImages] = useState([]);
  const [unitPageData, setUnitPageData] = useState({
    unitId: '',
    unitTitle: '',
    unitDescription: '',
    unitVisibility: '',
    unitDraft: false,
    unitPassword: '',
    url: UNITBASEURLFORWEB,
    customURI: 'enter-uri',
    publishDate: date,
    seoUnitTitle: '',
    seoKeywords: '',
    seoMetaDescription: '',
  });

  //***** States *****//
  // Filter Modal States
  const [listOpen, setListOpen] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const [filters, setFilters] = useState('');
  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const [pageEditModal, setPageEditModal] = useState(false);
  const [ColumnSetting1] = useState(['action']);
  const [ColumnSetting2] = useState(['status']);
  const [ColumnSetting3] = useState(['created']);
  const [ColumnSetting4] = useState(['campusName']);

  const dataProviders = [
    {
      columnName: ColumnSetting4,
      func: setName,
    },
    {
      columnName: ColumnSetting1,
      func: (restProps) =>
        EditAction(restProps, () => onEditActionClick(restProps?.row)),
    },
    {
      columnName: ColumnSetting2,
      func: UnitPageStatus,
    },
    {
      columnName: ColumnSetting3,
      func: CampusPageDate,
    },
  ];

  const getCampusesPageListing = (search, filter) => {
    setUnitListLoading(true);
    ApiController.getUnitPageListCall(search, filter, (response) => {
      if (response?.success) {
        const UnitList = response?.data
          ?.map((item) => {
            return {
              id: item?.id,
              unit: item?.title,
              author: item?.author_name,
              date: item?.created_at,
              status: item?.publish,
              item: item,
              unitNumber: item?.unit_number,
              campusName: item?.campus_name,
            };
          })
          ?.sort((a, b) => b?.id - a?.id);
        setUnitListing(UnitList);
        setUnitListLoading(false);
      } else {
        setUnitListing([]);
        setUnitListLoading(false);
      }
    });
  };

  useEffect(() => {
    getCampusesListing();
    getCampusesPageListing();
  }, []);

  const getCampusesListing = (name, zones, status) => {
    setcampusPageListLoading(true);
    ApiController.fetchCampusesCall(name, zones, status, (response) => {
      if (response?.success) {
        const sortArray = response?.data?.sort(function (a, b) {
          return a.id - b.id || a.name.localeCompare(b.name);
        });
        setCampusesList(sortArray);
        setcampusPageListLoading(false);
      }
    });
  };

  const onSuccesfullyEditUnit = () => {
    setPageEditModal(false);
    setUnitPageData({
      unitId: '',
      unitTitle: '',
      unitDescription: '',
      unitVisibility: '',
      unitDraft: '',
      unitPassword: '',
      seoUnitTitle: '',
      seoKeywords: '',
      seoMetaDescription: '',
    });
    getCampusesPageListing();
    setUnitPageBtnLoading(false);
  };

  const getUnitDetails = (id) => {
    setUnitPageLoading(true);
    setUnitPageBtnLoading(true);
    ApiController.unitDetailsCall(id, (response) => {
      const res = response?.data;
      if (response?.success) {
        setImages(res?.unit_image_url);
        setUnitData(res);
        setUnitPageData({
          unitId: res?.id,
          unitTitle: res?.unit_page?.title,
          unitDescription: res?.unit_page?.description,
          unitVisibility: res?.unit_page?.visibility,
          unitDraft: res?.unit_page?.publish ? false : true,
          unitPassword: res?.unit_page?.password,
          url: UNITBASEURLFORWEB,
          customURI: res?.unit_page?.end_url
            ? res?.unit_page?.end_url
            : 'enter-uri',
          publishDate:
            res?.unit_page?.unit_publish_date !== null
              ? res?.unit_page?.unit_publish_date?.split('T')[0]
              : date,
          seoUnitTitle: res?.unit_page.unit_seo_title || '',
          seoKeywords: res?.unit_page.unit_seo_keywords?.join(','),
          seoMetaDescription: res?.unit_page.unit_seo_description || '',
        });
        setUnitPageLoading(false);
        setUnitPageBtnLoading(false);
      } else {
        setUnitPageLoading(false);
        setUnitPageBtnLoading(false);
      }
    });
  };

  const createUnitPayload = () => {
    let payload = {
      unit_page: {
        title: unitPageData?.unitTitle || '',
        description: unitPageData?.unitDescription || '',
        publish: unitPageData?.unitDraft ? false : true,
        visibility: unitPageData?.unitVisibility ? unitPageData?.unitVisibility : 'PUBLIC',
        password:
          unitPageData?.unitVisibility === 'PUBLIC'
            ? ''
            : unitPageData?.unitPassword,
        end_url_during_update: unitPageData?.customURI,
        unit_publish_date: `${unitPageData.publishDate}T00:00:00`,
        unit_seo_title: unitPageData?.seoUnitTitle || "",
        unit_seo_keywords: unitPageData?.seoKeywords?.trim()?.length > 0 ? unitPageData?.seoKeywords?.trim()?.split(',')?.filter((item) => item !== "" && item !== " ") : "",
        unit_seo_description: unitPageData?.seoMetaDescription || "",
      },
    };
    return payload;
  };

  const createImagePayload = () => {
    let formData = new FormData();
    images?.map((item, index) => {
      return index === 0
        ? formData.append('image', item)
        : formData.append(`image${index}`, item);
    });
    return formData;
  };

  const uploadImages = (id) => {
    ApiController.addUnitsImagesCall(id, createImagePayload(), (response) => {
      if (response.success) {
      } else {
      }
    });
  };

  const deleteImages = (id) => {
    ApiController.deleteUnitsImagesCall({ ids: id }, (response) => {
      if (response.success) {
        setDeletedImages([]);
      }
    });
  };

  const checkforImageUpload = (id) => {
    if (
      images?.length > 0 &&
      images?.filter((item) => typeof item === 'object')?.length
    ) {
      uploadImages(id);
    }
    if (deletedImages?.length > 0) {
      deleteImages(deletedImages);
    }
  };

  const checkEmpty = (data) => {
    const obj = {
      unitTitle: data?.unitTitle,
      unitDescription: data?.unitDescription.replaceAll(/<[^>]*>/g, ''),
    };
    Object.keys(obj).forEach((item) => {
      if (!obj[item]) {
        if (error[item] !== undefined) {
          setError((prevError) => ({
            ...prevError,
            [item]: 'This field is required',
          }));
        }
      }
    });
  };

  const updateUnitPage = () => {
    setUnitPageBtnLoading(true);
    checkEmpty(unitPageData);
    setUriError({});
    let payload = createUnitPayload();
    if (
      error?.unitTitle === '' &&
      error?.unitDescription === '' &&
      error?.unitImages === ''
    ) {
      ApiController.updateUnitCall(unitData?.id, payload, (response) => {
        if (response?.success) {
          checkforImageUpload(unitData?.id);
          onSuccesfullyEditUnit();
          setUriError({});
        } else {
          const error = response?.data?.reduce(
            (curr, acc) => ({ ...curr, ...acc }),
            {}
          );
          setUriError(error);
          setUnitPageBtnLoading(false);
        }
      });
    } else {
      setUnitPageBtnLoading(false);
    }
  };

  const onEditActionClick = (unitData) => {
    setStatus(unitData?.status);
    setPageEditModal(true);
    getUnitDetails(unitData?.item?.unit_id);
  };

  //************************* Search Bar Start *******************************//

  //***** States *****//
  const [searchText, setSearchText] = useState('');

  // Search User Method
  const onChangeSearch = (e) => {
    if (e.target.value !== '') {
      setSearchText(e.target.value);
    } else {
      setSearchText('');
      getCampusesPageListing('', filters);
    }
  };
  const onUnitsSearch = () => {
    if (searchText) {
      getCampusesPageListing(searchText, filters);
    }
  };
  // onSearch Clear
  const onClear = () => {
    setSearchText('');
    getCampusesPageListing('', filters);
  };
  //************************* Search Bar End *******************************//

  //************************* FILTERS METHODS START *******************************//

  //Filters Methods
  const handleClick = (event) => {
    if (!campusPageListLoading) {
      setAnchorEl(event.currentTarget);
    }
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const clickAwayHandler = () => {
    setAnchorEl(false);
  };
  const handleClickForParent = (title) => {
    setListOpen({
      ...listOpen,
      [title]: !listOpen[title],
    });
  };

  const onChangeFilter = (item, index) => {
    if (filters === item?.title) {
      setFilters('');
      getCampusesPageListing(searchText, '');
    } else {
      setFilters(item?.name);
      getCampusesPageListing(searchText, item?.name);
    }
  };

  const onClearFilter = () => {
    setFilters('');
    getCampusesPageListing(searchText, '');
  };

  //************************* FILTERS METHODS END *******************************//

  return (
    <div>
      <UnitPageUI
        // Selestion State
        selectionIds={selectionIds}
        setSelectionIds={setSelectionIds}
        unitColumnData={unitPageColumnData}
        unitColumnExtensionsData={unitPageColumnExtensionsData}
        unitRowData={unitListing.length > 0 ? unitListing : []}
        loading={unitListLoading}
        // Table Methods
        dataProviders={dataProviders}
        // SearchBar States
        searchText={searchText}
        // SearchBar Method
        onChangeSearch={onChangeSearch}
        onUnitsSearch={onUnitsSearch}
        onClear={onClear}
        // Filters States & Methods
        anchorEl={anchorEl}
        clickAwayHandler={clickAwayHandler}
        id={id}
        open={open}
        handleClick={handleClick}
        handleClose={handleClose}
        listOpen={listOpen}
        campusesList={campusesList}
        handleClickForParent={handleClickForParent}
        onChangeFilter={onChangeFilter}
        onClearFilter={onClearFilter}
        filters={filters}
      />
      <UnitPageEdit
        open={pageEditModal}
        close={() => {
          Object.keys(error).forEach((item) => {
            setError((prevError) => ({
              ...prevError,
              [item]: '',
            }));
          });
          setPageEditModal(false);
        }}
        title='Edit Unit Page'
        unitPageData={unitPageData}
        setunitPageData={setUnitPageData}
        loading={unitPageLoading}
        unitPageBtnLoading={unitPageBtnLoading}
        onUpdate={updateUnitPage}
        images={images}
        setImages={setImages}
        deletedImages={deletedImages}
        setDeletedImages={setDeletedImages}
        unitData={unitData}
        error={error}
        setError={setError}
        backendError={uriError}
      />
    </div>
  );
};

export default UnitPage;
