import React, { useRef } from 'react';
import { mapApiKey } from '../../utils/network/constants';
// import {
//   LoadScript,
//   StandaloneSearchBox,
//   useJsApiLoader,
// } from '@react-google-maps/api';
// import TextField from '../inputField';

import Autocomplete from "react-google-autocomplete";
import { Styles } from '../../utils/style/GlobalStyles';

// const libraries = ['places'];
const AutoCompleteGooglePlace = ({ setnewCampus, newCampus, label }) => {
  const autocompleteRef = useRef();
  // const { isLoaded, loadError } = useJsApiLoader({
  //   googleMapsApiKey: mapApiKey,
  //   libraries: libraries,
  // });

  const handlePlaceChanged = (event) => {
    // const [place] = inputRef.current.getPlaces();
    // if (place) {
    setnewCampus((prev) => ({
      ...prev,
      street: event.formatted_address,
      longitude: event.geometry.location.lng(),
      latitude: event.geometry.location.lat(),
    }));
    // }
  };
  const handleStreetChange = (event) => {
    const streetValue = event.target.value;
    setnewCampus((prev) => ({
      ...prev,
      street: streetValue,
    }));
  };

  return (
    <div className='flex-end mt-5'>
      {/* <LoadScript googleMapsApiKey={mapApiKey} libraries={libraries}>
        {isLoaded ? (
          <StandaloneSearchBox
            onLoad={(ref) => (inputRef.current = ref)}
            onPlacesChanged={handlePlaceChanged}
          >
            <TextField
              type='text'
              label={'Street'}
              value={newCampus?.street}
              placeholder=''
              onChange={handleStreetChange}
            />
          </StandaloneSearchBox>
        ) : null}
      </LoadScript> */}
      <p style={Styles.smallTextWhite} className={`mb-1`}>
        {label}
      </p>
      <Autocomplete
        apiKey={mapApiKey}
        style={{
          width: "100%",
          height: "44px",
          border: 'none',
          paddingInline: '10px',
          borderRadius: '8px',
          backgroundColor: '#1B1B23',
          fontSize: '13px',
          color: 'white',
          '&::before': {
            border: 'none'
          },
          '&:hover::before': {
            border: 'none'
          }
        }}
        value={newCampus?.street}
        onChange={handleStreetChange}
        onPlaceSelected={handlePlaceChanged}
        ref={autocompleteRef}
      />
    </div>
  );
};

export default AutoCompleteGooglePlace;
