import { RichTextEditor } from '@mantine/rte';
import './style.css';

function RichTextInput({ value, setValue }) {
  const handlePaste = (e) => {
    e.preventDefault();
    const clipboardData = e.clipboardData || window.clipboardData;
    const html = clipboardData.getData('text/html');

    if (html) {
      // Create a temporary container to parse HTML
      const tempDiv = document.createElement('div');
      tempDiv.innerHTML = html;

      // Update text color to white
      tempDiv.querySelectorAll('*').forEach((node) => {
        node.style.color = 'white';
      });

      // Insert the modified content
      const modifiedHtml = tempDiv.innerHTML;
      document.execCommand('insertHTML', false, modifiedHtml);
    } else {
      // Fallback for plain text
      const text = clipboardData.getData('text/plain');
      document.execCommand('insertText', false, text);
    }
  };

  return (
    <RichTextEditor
      sticky={true}
      value={value}
      onChange={setValue}
      controls={[
        ['bold', 'italic', 'underline', 'link'],
        ['unorderedList', 'h1', 'h2', 'h3'],
        ['alignLeft', 'alignCenter', 'alignRight'],
      ]}
      onPaste={handlePaste}
    />
  );
}

export default RichTextInput;
