import ImageGalleryNew from '../../Gallery';
import { gray } from '../../../utils/style/GlobalVariables';
import AssetsImages from '../../../assets';
import { Box } from '@mui/material';


const CampusPreviewPage = ({ data }) => {
  const images = [AssetsImages.loginBG];
  return (
    <section>
      <div className='flex items-center justify-center mt-10'>
        <p className='font-semibold text-white text-3xl lg:w-96 text-center'>
          Campus Preview Page
        </p>
      </div>
      <div className='px-6 py-10 mx-auto'>
        <div className=''>
          <div className='mt-8 w-full md:w-1/2 float-left pr-6 pb-6'>
            {data?.campusImages?.length > 0 ? (
              <ImageGalleryNew images={data?.campusImages} />
            ) : (
              <ImageGalleryNew images={images} />
            )}
          </div>

          <div>
            <h1 className='font-semibold text-white text-2xl'>
              {data?.title}
            </h1>
            <Box
              style={{ color: gray }}
              sx={{
                h1: {
                  fontSize: '2rem',
                  color: 'white',
                  lineHeight: 1.2,
                  mt: 2,
                },
                h2: {
                  fontSize: '1.6rem',
                  color: 'white',
                  lineHeight: 1.2,
                  mt: 2,
                },
                h3: {
                  fontSize: '1.3rem',
                  color: 'white',
                  lineHeight: 1.2,
                  mt: 2,
                },
                li: {
                  listStyleType: 'disc',
                  marginLeft: '30px',
                },
                a: {
                  color: ' #4d4dff',
                }
              }}
              sx={{
                h1: {
                  fontSize: '2rem',
                  color: 'white',
                  lineHeight: 1.2,
                  mt: 2,
                },
                h2: {
                  fontSize: '1.6rem',
                  color: 'white',
                  lineHeight: 1.2,
                  mt: 2,
                },
                h3: {
                  fontSize: '1.3rem',
                  color: 'white',
                  lineHeight: 1.2,
                  mt: 2,
                },
                li: {
                  listStyleType: 'disc',
                  marginLeft: '30px',
                },
                a: {
                  color: ' #4d4dff',
                }
              }}
              dangerouslySetInnerHTML={{ __html: data?.description }}
            />
          </div>
        </div>
      </div>
    </section >
  );
};

export default CampusPreviewPage;
